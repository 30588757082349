import React from 'react';

import { graphql } from 'gatsby';

import NotFoundPage from '../components/not-found/not-found-page';

export const query = graphql`
  query NotFoundPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const NotFoundTemplate = (props) => {
  return <NotFoundPage {...props} />;
};

export default NotFoundTemplate;
