import React from 'react';

import { useFunnelRouting } from '../../context/funnel-routing-provider';
import { Link } from 'gatsby-theme-material-ui';

import { Container, Box, Typography, Paper } from '@mui/material';

import Header from '../shared/header-without-call-to-action';
import Footer from '../shared/footer';

const NotFoundPage = ({ data }) => {
  const page = data.kalansoPage;
  const routing = useFunnelRouting();
  const contactFormRoute = routing.get('contact');

  return (
    <>
      <Header page={page} />
      <Container
        maxWidth="sm"
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper elevation={1}>
          <Box sx={{ padding: '1rem' }}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h3">PAGE NOT FOUND</Typography>
            <Typography>
              Hmm. Looks like we can't find that page. Maybe check the address
              again, try{' '}
              <Link
                to="/"
                sx={{ color: 'secondary.main', textDecoration: 'none' }}
              >
                signing in first,
              </Link>{' '}
              or let us know about the trouble using the{' '}
              <Link
                to={contactFormRoute.url}
                sx={{ color: 'secondary.main', textDecoration: 'none' }}
              >
                contact form.
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>

      <Footer page={page} />
    </>
  );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
